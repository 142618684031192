import React,{Component} from 'react';
import "./introduction.scss"
import helpers from "../helpers/helpers"
import {Card, CardContent, Button, CardMedia} from  '@material-ui/core/';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class Introduction extends Component{

    render(){
        const img=this.props.image;
        const content = this.props.content;
        return(
                    <Card elevation={0} className="content-intro">
                        <CardMedia
                          className="intro-img"
                          image={img}
                          title={content.title}
                        />
                        <CardContent className="content-intro-incipit">
                            <div className="titre-intro">
                                <h3>{content.title}</h3>
                            </div>
                            <div  className="text" dangerouslySetInnerHTML={helpers.cleanHTML(content.content)}/>
                            <div className="intro-bloc-btn">
                                <Button 
                                    onClick={this.props.handleNext}                                
                                    className={`button-intro btn-bg-${this.props.couleur}`}
                                    style={{ backgroundColor: 'transparent' }}>
                                        {helpers.findLabelInData("BTN-START")}
                                        <ArrowForwardIcon className="arrow-btn-intro"/>
                                </Button>
                            </div>  
                        </CardContent>
                    </Card>
        )
        
    }

}
export default Introduction;