import React,{Component} from 'react';
import "./conclusion.scss";
import Helpers from "../helpers/helpers";
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Grid, Card, CardContent, Button, CardMedia} from  '@material-ui/core/';


class Conclusion extends Component{
    render(){
        const img=this.props.content.thumbnail!==undefined?`${window.PUBLIC_URL}${this.props.content.thumbnail}`:this.props.image;
        const content = this.props.content;
        return(
            <Grid container spacing={3}>
                 <Grid item xs={12}>
                    <Card elevation={0} className="content-conclusion">
                        <CardMedia
                          className="conclusion-img"
                          image={img}
                          title={content.title}
                        />
                        <CardContent className="content-conclusion-incipit">
                             <div className="titre-conclusion">
                                 <h3>{content.title}</h3>
                                 </div>
                            <div className="text-conclusion" dangerouslySetInnerHTML={{__html:content.content}}/>
                            <div className="conclusion-bloc-btn">
                                <Button 
                                    onClick={this.props.handleNext}                                
                                    className={`button-conclusion btn-bg-${this.props.couleur}`}
                                    style={{ backgroundColor: 'transparent' }}>
                                        Continuer
                                        <ArrowForwardIcon className="arrow-btn-conclusion"/>
                                </Button>
                            </div>  
                        </CardContent>
                    </Card>
                 </Grid>
            </Grid>
        )
        
    }

}
export default Conclusion;