import React,{Component} from 'react';
import "./quizz.scss";
import {Paper, Card, CardContent, CardMedia,CardActions, Button, Radio, Slide} from  '@material-ui/core/';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import helpers from "../helpers/helpers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



class Autodiag extends Component{
    constructor (props){
        super(props)
        this.state={
            questionEnCours: 0,
            answer: new Map(),
            questionComplete: false,
            profil: [0,0,0,0],
            questionsType:[0,0,0,0],
            items : this.props.content.items,
            slide: true,
            start:true,
            end:false,
            user:[]
        }
        this.validateQuestion=this.validateQuestion.bind(this);
        this.changeQuestion=this.changeQuestion.bind(this);
    }

    handleClick(indice, value){
        let mapCopy = this.state.answer;
        if(this.state.answer.has(indice)){
            mapCopy.delete(indice);
        }
        else {
            mapCopy=new Map();
            mapCopy.set(indice, value);
        }
        this.setState({
            answer: mapCopy,
        }); 
    }
    restart(){
        this.setState(
            {
                questionEnCours: 0,
                answer: new Map(),
                questionComplete: false,
                profil: [{indice:0,value:0},{indice:1,value:0},{indice:2,value:0},{indice:3,value:0}],
                slide: true,
                start:true,
                end:false,
                user:[]
            }
        )
    }
    changeQuestion(){
        const indice = this.state.questionEnCours+1;
        if(indice < this.props.content.questions.length){
            this.setState({
                questionEnCours: indice,
                questionComplete: false,
                answer: new Map(),
                slide: !this.state.slide,
                start:false,
            });
        }else{
            this.calculProfil()
            //this.props.handleNext();
        }
    }
    calculProfil(){
        const {profil,questionsType}=this.state
        const feedbacks=[]
        for(let i=0;i<questionsType.length;i++){
            const c=(profil[i]*100/questionsType[i]);
            console.log("RESULT ",i, " ",c)
            if (c<40)
                feedbacks.push(this.props.content.feedbacks[i][0])
            else if(c>=40 && c <60)
                feedbacks.push(this.props.content.feedbacks[i][1])
            else if (c>=60)
                feedbacks.push(this.props.content.feedbacks[i][2])
        }
     
        this.setState({end:true,user:feedbacks})
    }
    validateQuestion(){
        const a=this.state.answer;
        const r=this.state.profil;
        const tt=this.state.questionsType
        for(let v of a){
            console.log("a ", v[1])
            r[this.props.content.questions[this.state.questionEnCours].categorie]+=v[1]
            tt[this.props.content.questions[this.state.questionEnCours].categorie]+=4
            
        }
        console.log("profil ! ",r);
        console.log("nb question ! ",tt );
        this.setState({profil:r, questionsType:tt, questionComplete:false,slide:false}, this.changeQuestion())
    }
    displayProfil(){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
        <Slide 
            direction={'left'}
            in={this.state.slide}
            mountOnEnter unmountOnExit>
                <CardContent className="feedback-viewer">
                    <div className="p-header">
                        <p className="excipit">{this.props.content.excipit}</p>
                    </div>
                    {
                        this.state.user.map((item,key)=>{
                            return(
                                <Paper elevation={0} key={key}className="p-description">
                                    <h3>{item.type}</h3>
                                    <p>{item.lib}</p>
                                </Paper>
                            )
                        })
                    }
                </CardContent>  
            </Slide>
        )
    }
    displayQuestions(question){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
            <Slide 
                direction={'left'}
                in={this.state.slide}
                mountOnEnter unmountOnExit>
                <CardContent className="quizz-viewer">
                    <p className="question-bloc bg-1">{question.lib}</p>
                    <div className="bloc-items">
                        {this.state.items.map((item,i)=>{
                                return(
                                <Card key={i} className={`quizz-item ${this.state.answer.has(i) ? "selected":"" }`} elevation={0} onClick={() => this.handleClick(i, item.value)}>
                                    <Radio value={i} checked={this.state.answer.has(i)}/>  
                                    <p>{item.lib}</p>
                                </Card>
                                )
                            })}
                    </div>
                </CardContent>  
            </Slide>
        )
    }
    
    displayFeedBack(){
        return(
            <div className={(this.state.questionFeedback.value === "true" ? "quizz-good" : "quizz-bad") + " quizz-feedback"}>
                <p dangerouslySetInnerHTML={helpers.cleanHTML(this.state.questionFeedback.content)}></p>
            </div>
        )
    }

    displayIcons(i){
        if(this.state.answer.has(i)){
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
             return <CheckCircleOutlinedIcon className="green"/>
            else
            return <HighlightOffOutlinedIcon className="red"/>
        }else{
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
            return <CheckCircleOutlinedIcon  className="green"/>
        }

    }
    
    render(){
        const content = this.props.content;
        const question = this.props.content.questions[this.state.questionEnCours];
        const img=this.props.image;
        return( 
            <Card elevation={0} className="content-wrapper">
                <CardMedia
                        className="intro-img"
                        image={img}
                        title={content.title}
                />
                <Card  className="content-quizz" variant={"outlined"}>
                    <div className="titre-quizz">
                        {this.state.start&&<p dangerouslySetInnerHTML={helpers.cleanHTML(content.incipit)} />}
                    </div>
                    {this.state.end ?
                    this.displayProfil()
                    :
                    this.displayQuestions(question)}
                    {this.state.end ?
                        <CardActions className="steps-bloc-btn">
                            <Button 
                            disabled={false}
                            onClick={()=>this.restart()} 
                            className='button-quizz btn-bg-1'>
                                {helpers.findLabelInData("BTN-RESTART")}
                            </Button>
                            <Button 
                            disabled={false}
                            onClick={()=>this.props.handleNext()} 
                            className='button-quizz btn-bg-1'>
                                <>
                                    {helpers.findLabelInData("BTN-CONTINUER")}
                                    <ArrowForwardIcon className="btn-quizz-arrow"/>
                                </>
                            </Button>
                        </CardActions>
                    :
                    <CardActions className="steps-bloc-btn">
                        <Button 
                        disabled={this.state.answer.size === 0 ? true : false}
                        onClick={this.state.questionComplete ? this.changeQuestion : this.validateQuestion} 
                        className='button-quizz btn-bg-1'>
                            {this.state.questionComplete ? 
                            <>
                                {helpers.findLabelInData("BTN-CONTINUER")}
                                <ArrowForwardIcon className="btn-quizz-arrow"/>
                            </>
                            : 
                                helpers.findLabelInData("BTN-VALIDER")
                            }
                        </Button>
                    </CardActions>
                    }
                </Card>
            </Card>
        )
    }
}
export default Autodiag;