import React,{Component} from 'react';
import "./video.scss";
import helpers from "../helpers/helpers"
import {Grid, Button} from  '@material-ui/core/';
import ReactPlayer from 'react-player'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


class Video extends Component{
    constructor(props){
        super(props)
        this.state={
            finished:false
        }
        this.onVideoEnd=this.onVideoEnd.bind(this)
    }
    onVideoEnd(){
        this.setState({finished:true})
    }
    render(){
        const video=`${window.PUBLIC_URL}${this.props.content.content.file}`;
        console.log(this.props.content.content);
        const content = this.props.content;
        return(
            <Grid className="content-video" container spacing={1}>
                <div className="incipit-video">
                        {content.incipit}
                    </div>
                 <div className="video-container">
                    <div className="video-wrapper-2">
                        <ReactPlayer
                            playing
                            url={video}
                            controls={true}
                            width="100%"
                            height="100%"
                            className="video-player"
                            onEnded={this.onVideoEnd}
                        />
                    </div>
                    {this.state.finished&&<div className="video-bloc-btn">
                        <Button 
                            onClick={this.props.handleNext}                                
                            className='button-video'
                            
                            style={{ backgroundColor: 'transparent' }}>
                                {helpers.findLabelInData("BTN-CONTINUER")}
                                <ArrowForwardIcon className="btn-video-arrow"/>
                        </Button>
                    </div> }
                    
                 </div>
            </Grid>
        )
        
    }

}
export default Video;