import React,{Component} from 'react';
import "./quizz.scss";
import {Paper, Card, CardContent, CardMedia,CardActions, Button, Radio, Slide} from  '@material-ui/core/';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import helpers from "../helpers/helpers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



class Diag extends Component{
    constructor (props){
        super(props)
        this.state={
            questionEnCours: 0,
            answer: new Map(),
            questionComplete: false,
            questionFeedback:{},
            profil: [{indice:0,value:0},{indice:1,value:0},{indice:2,value:0},{indice:3,value:0}],
            slide: true,
            start:true,
            end:false,
            user:{}
        }
        this.validateQuestion=this.validateQuestion.bind(this);
        this.changeQuestion=this.changeQuestion.bind(this);
        this.restartQuestion=this.restartQuestion.bind(this)
    }

    handleClick(indice, value){
        let mapCopy = this.state.answer;
        if(this.state.answer.has(indice)){
            mapCopy.delete(indice);
        }
        else {
            mapCopy=new Map();
            mapCopy.set(indice, value);
        }
        this.setState({
            answer: mapCopy,
        }); 
    }
    restart(){
        this.setState(
            {
                questionEnCours: 0,
                answer: new Map(),
                questionComplete: false,
                profil: [{indice:0,value:0},{indice:1,value:0},{indice:2,value:0},{indice:3,value:0}],
                slide: true,
                start:true,
                end:false,
                user:{}
            }
        )
    }
    restartQuestion(){
        this.setState(
            {
                answer: new Map(),
                questionComplete: false,
                slide: true,
                questionFeedback:{}
            }
        )
    }
    changeQuestion(){
        const indice = this.state.questionEnCours+1;
        if(indice < this.props.content.questions.length){
            this.setState({
                questionEnCours: indice,
                questionComplete: false,
                answer: new Map(),
                slide: !this.state.slide,
                start:false
            });
        }else{
            this.props.handleNext();
        }
    }

    validateQuestion(){
        let result;          
        let feedback = {
            value: "",
            content: ""
        }  
        result = "false"; 
        //if only one is false, the feedback will be false
        for (let v of this.state.answer) {
            console.log("item ",v)
            if (v[1] === this.props.content.questions[this.state.questionEnCours].answer) { 
                result = "true"; 
            }
            const f=this.props.content.questions[this.state.questionEnCours].feedbacks.find(item=>item.value===v[1]);
            feedback.content=f.label;
            feedback.value=result
        }
        console.log(result);
        console.log(feedback);
        this.setState({
            questionComplete: true,
            questionFeedback: feedback
        })
    }
    calculProfil(){
        const r=this.state.profil.sort((a,b)=>b.value-a.value).slice(0,2);
        const profils=this.props.content.profils
        let match=0
        let pro
        console.log("result --> ",r);
        for(let i of profils){
            match=0;
            console.log("***************** ",i);
            for( let res of r){
                console.log("---------------------",res)
                if(i.dimensions.find(e=>e===res.indice)!==undefined){
                    console.log("find")
                    match+=1
                }else{
                    break; 
                }
            }
            if(match>=2){
                console.log("profil ", match," ",i," ",i.titre);
                pro=i
            }
        }
        this.setState({end:true,user:pro})
    }
    displayFeedBack(){
        console.log("FEEDBACK")
        return(
            <div className={(this.state.questionFeedback.value === "true" ? "quizz-good" : "quizz-bad") + " quizz-feedback"}>
                <p dangerouslySetInnerHTML={helpers.cleanHTML(this.state.questionFeedback.content)}></p>
            </div>
        )
    }
    displayProfil(){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
        <Slide 
            direction={'left'}
            in={this.state.slide}
            mountOnEnter unmountOnExit>
                <CardContent className="feedback-viewer">
                    <div className="p-header">
                        <CardMedia
                        className="intro-img"
                        image={this.state.user.thumbnail}
                        title={"profil"}
                        />
                         {this.state.questionComplete ? this.displayFeedBack() : null}
                        <p className="excipit">{this.props.content.excipit}</p>
                        <h3>{this.state.user.titre}</h3>
                    </div>
                    <Paper className="p-description">
                        <p>{this.state.user.description}</p>
                    </Paper>
                    <Paper className="p-details">
                        <div>
                            <h3>POINTS FORTS</h3>
                            <div dangerouslySetInnerHTML={helpers.cleanHTML(this.state.user.srengths)}/>
                        </div>
                        <div>
                            <h3>POINTS D'AMÉLIORATION</h3>
                            <div dangerouslySetInnerHTML={helpers.cleanHTML(this.state.user.weaks)}/>
                        </div>
                    </Paper>
                </CardContent>  
            </Slide>
        )
    }
    displayQuestions(question,dimensions){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
            <Slide 
                direction={'left'}
                in={this.state.slide}
                mountOnEnter unmountOnExit>
                <CardContent className="quizz-viewer">
                    <div className="bloc-items">
                        {dimensions.map((item,i)=>{
                                return(
                                <Card key={i} className={`quizz-item ${this.state.answer.has(i) ? "selected":"" }`} elevation={0} onClick={() => this.handleClick(i, i)}>
                                    <Radio value={i} checked={this.state.answer.has(i)}/>  
                                    <p>{item}</p>
                                </Card>
                                )
                            })}
                    </div>
                </CardContent>  
            </Slide>
        )
    }
    
    displayFeedBack(){
        return(
            <div className={(this.state.questionFeedback.value === "true" ? "quizz-good" : "quizz-bad") + " quizz-feedback"}>
                <p dangerouslySetInnerHTML={helpers.cleanHTML(this.state.questionFeedback.content)}></p>
            </div>
        )
    }

    displayIcons(i){
        if(this.state.answer.has(i)){
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
             return <CheckCircleOutlinedIcon className="green"/>
            else
            return <HighlightOffOutlinedIcon className="red"/>
        }else{
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
            return <CheckCircleOutlinedIcon  className="green"/>
        }

    }
    
    render(){
        const content = this.props.content;
        const question = this.props.content.questions[this.state.questionEnCours];
        const dimensions = this.props.content.dimensions
        const img=this.props.image;
        return(
                <Card elevation={0} className="content-wrapper">
                    <CardMedia
                        className="intro-img"
                        image={`${window.PUBLIC_URL}${img}`}
                        title={content.title}
                    />
                     {this.state.questionComplete ? this.displayFeedBack() : null}
                    <Card  className="content-quizz" variant={"outlined"}>
                    <div className="titre-quizz">
                         {this.state.start&&<p dangerouslySetInnerHTML={helpers.cleanHTML(content.incipit)} />}
                        <h3 className="question-bloc">{question.lib}</h3>
                    </div>
                    {this.state.end ?
                    this.displayProfil()
                    :
                    this.displayQuestions(question, dimensions)}
                    {this.state.end ?
                        <CardActions className="steps-bloc-btn">
                            <Button 
                            disabled={false}
                            onClick={()=>this.restart()} 
                            className='button-quizz'>
                                {helpers.findLabelInData("BTN-RESTART")}
                            </Button>
                            <Button 
                            disabled={false}
                            onClick={()=>this.props.handleNext()} 
                            className='button-quizz'>
                                <>
                                    {helpers.findLabelInData("BTN-CONTINUER")}
                                    <ArrowForwardIcon className="btn-quizz-arrow"/>
                                </>
                            </Button>
                        </CardActions>
                    :
                    <CardActions className="steps-bloc-btn">
                        <Button 
                        disabled={this.state.answer.size === 0 ? true : false}
                        onClick={this.state.questionComplete ? this.state.questionFeedback.value==="false"? this.restartQuestion : this.changeQuestion : this.validateQuestion} 
                        className={`button-quizz btn-bg-${this.props.couleur}`}>
                            {this.state.questionComplete ? 
                                this.state.questionFeedback.value==="false"  ?
                                "RECOMMENCER"
                                :
                                helpers.findLabelInData("BTN-CONTINUER")
                            : 
                                helpers.findLabelInData("BTN-VALIDER")
                            }
                        </Button>
                    </CardActions>
                    }
                    </Card>
                </Card>
        )
    }
}
export default Diag;