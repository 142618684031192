import React,{Component} from 'react';
import "./infographie.scss"
import helpers from "../helpers/helpers";
import {Grid, Paper,Card, CardContent, CardActions, Button} from  '@material-ui/core/';
import Image from "react-image-enlarger";

const couleurs=['#a097e4','#f1b56e','#85d1a3']


class Infographie extends Component{
    constructor()
    {
        super();
        this.state={
            zoomed: false
        }
    }

    setZoomed(bool){
        this.setState({
            zoomed: bool,
        })
    }
    openFile(pFile){
        const file=`${window.PUBLIC_URL}${pFile}`;
        window.open(file,"_blank")
    }
    render(){
        const content = this.props.content;
        const img=`${window.PUBLIC_URL}${content.image}`;
        console.log(content);
        return(
            <div className="content-bloc">
                <div className={`wrapper-i cadre-${this.props.couleur}`}>
                    <Image
                        className="infographie-img"
                        zoomed={this.state.zoomed}
                        src={img}
                        onClick={() => this.setZoomed(true)}
                        onRequestClose={() => this.setZoomed(false)}
                    />   
                </div>
                <CardContent className="content-infographie" elevation={0}>
                            <h3 style={{color:couleurs[this.props.couleur]}}>{content.incipit}</h3>
                            <div className={`infographie-comment bg-incipit-${this.props.couleur}`}>
                                <div dangerouslySetInnerHTML={helpers.cleanHTML(content.content)}/>
                            </div>
                            <CardActions className="infographie-bloc-btn">
                                {
                                    content.file!==undefined&&
                                    <Button variant="outlined" disableElevation color="primary" onClick={()=>this.openFile(content.file)} className='button-download'>{"TÉLÉCHARGER LA FICHE"}</Button>

                                }
                                <Button variant="contained" disableElevation color="primary" onClick={this.props.handleNext} className={`button-intro btn-bg-${this.props.couleur}`}>{helpers.findLabelInData("BTN-CONTINUER")}</Button>
                            </CardActions>  
                        </CardContent>
                </div>
        )
        
    }

}
export default Infographie;