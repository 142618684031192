import React,{Component} from 'react';
import "./steps.scss";
import helpers from "../helpers/helpers"
import NavBar from "./navbar";
import UserStore from "../stores/UserStore";
import * as UserActions from "../actions/UserActions";
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Introduction from "./introduction";
import Conclusion from "./conclusion";
import Sauvegarde from "./sauvegarde";
import Quizz from "./quizz";
import Images from "./images";
import Video from "./video";
import Cases from "./cases";
import Diag from "./diag";
import Autodiag from "./autodiag"
import Infographie from "./infographie"
import {Grid, Card, CardContent, Button, Popover, Slide, IconButton, CardMedia} from  '@material-ui/core/';
import Sim from './sim';
import WheelRPS from "./wheelRPS"
import Reveal from "./reveal"
import {ReactComponent as Checked} from "../images/checked.svg"
import {Notification} from "./units/basics"
import Swipe from "react-easy-swipe"


class Steps extends Component{
    constructor(props)
    {
        super(props);
        this.state={
            moduleEnCours : this.props.content,
            game: UserStore.getUser(),
            activityEnCours: 0,
            sequenceEnCours: 0,
            activityComplete:false,
            viewModuleMenu: true,
            slide: true,
            saveOption: false,
            displayNotif:true,
            infoBulle:false,
            anchorE1:null
        }
        this.handle_Back = this.handle_Back.bind(this);
        this.handlePrevactivity = this.handlePrevactivity.bind(this);
        this.handleNextActivity = this.handleNextActivity.bind(this);
        this.goToSauvegarde = this.goToSauvegarde.bind(this);
        this.goToSavedActivity = this.goToSavedActivity.bind(this);
        this.cleanProgress()
    }
    handlePrevactivity(){
        const { game, moduleEnCours, sequenceEnCours } = this.state;
        //let currentSequenceActivities = game.gamePath[moduleEnCours].module.sequences[sequenceEnCours].activities;
        const newActivity = this.state.activityEnCours-1;
        if(newActivity>=0){
            this.setState({
                slide: false,
            });
            setTimeout(function(){
                this.setState({
                    activityEnCours: newActivity,
                    activityComplete: false,
                });
            }.bind(this), 150);
            console.log('user : ', UserStore.getUser());


        }
    }
    handleNextActivity(){
        const chapters=window.APP_DATA.chapters
        const { game, moduleEnCours, sequenceEnCours } = this.state;
        //let currentSequenceActivities = game.gamePath[moduleEnCours].module.sequences[sequenceEnCours].activities;
        let currentSequenceActivities = chapters[moduleEnCours].sequences[sequenceEnCours].activities;
        const newActivity = this.state.activityEnCours + 1;
        

        if(newActivity === currentSequenceActivities.length){
            console.log("Next Module or Sequence",game.gamePath[moduleEnCours].sequence);

            //if(!game.gamePath[moduleEnCours].sequence.includes(sequenceEnCours)){
            if(!game.gamePath[moduleEnCours].sequence[sequenceEnCours].completed){
                let seqArray = game.gamePath[moduleEnCours].sequence;
                 //seqArray.push(sequenceEnCours);
                seqArray[sequenceEnCours].completed=true;
                let gamePath = game.gamePath;
                gamePath[moduleEnCours] = {
                    module: game.gamePath[moduleEnCours].module,
                    progress: game.gamePath[moduleEnCours].progress,
                    sequence: seqArray
                }
                UserActions.changeUser({
                    gamePath: gamePath,
                });
                this.updateModuleProgress();
            }

            this.setState({
                slide: false,
            });
            setTimeout(function(){
                this.setState({
                    viewModuleMenu : true,
                    sequenceEnCours: 0,
                    activityEnCours: 0,
                });
            }.bind(this), 150);

            console.log("seq finnished", game.gamePath[moduleEnCours].sequence);
            console.log('user : ', UserStore.getUser());
 
        }  
        else{
            this.setState({
                slide: false,
            });
            setTimeout(function(){
                this.setState({
                    activityEnCours: newActivity,
                    activityComplete: false,
                });
            }.bind(this), 150);
            console.log('user : ', UserStore.getUser());

        }
        console.log("activités de la séquence", currentSequenceActivities);
        console.log("activité en cours", newActivity);
    }
    cleanProgress(){
        const { game } = this.state;
        let result;
        let gamePath = game.gamePath;
        let seqChecked
        for(let i=0;i<gamePath.length;i++){
            result=0;
            seqChecked=0;
            gamePath[i].sequence.forEach(
                item=>item.completed ?seqChecked++ : null 
            )
            result=Math.round(seqChecked/ gamePath[i].sequence.length*100)
            if(result>95)
                result=100
            gamePath[i].progress=result;
        }
        UserActions.changeUser({
            gamePath: gamePath,
        });

    }
    updateModuleProgress(){
        const { game, moduleEnCours } = this.state;
        let result;
        let gamePath = game.gamePath;
        //let moduleProgress = game.gamePath[moduleEnCours].progress;
        let seqChecked=0
        gamePath[moduleEnCours].sequence.forEach(
            item=>item.completed ?seqChecked++ : null 
        )
        result=Math.round(seqChecked/ gamePath[moduleEnCours].sequence.length*100)


        /* if(moduleProgress < 100){
           // let nbSeq = game.gamePath[moduleEnCours].module.sequences.length
            let nbSeq = window.APP_DATA.chapters[moduleEnCours].sequences.length
            let fraction = Math.round(100/nbSeq);
            if(moduleProgress === 0){
                result = fraction;
            }
            else if(moduleProgress + fraction > 95){
                result = 100
            }
            else{
                result = (moduleProgress + fraction);
            }
        }
        else{
            result = moduleProgress;
        } */

        gamePath[moduleEnCours] = {
            module: game.gamePath[moduleEnCours].module,
            progress: result,
            sequence: game.gamePath[moduleEnCours].sequence
        }
        UserActions.changeUser({
            gamePath: gamePath,
        });
    }
    checkProgressComplete(){
        const {gamePath}=this.state.game
        let globalProgress=0
        gamePath.forEach(module=>{
            globalProgress+=module.progress
        })
        console.log("PROGRES TOTAL : ",globalProgress/gamePath.length);
        return Math.round(globalProgress/gamePath.length)
    }
    isCompleted(pSequence){
        const { game, moduleEnCours } = this.state;
        //console.log("completed :", moduleEnCours, pSequence, game.gamePath[moduleEnCours].sequence[pSequence].completed)
        return game.gamePath[moduleEnCours].sequence[pSequence].completed
    }
    goToSauvegarde(){
        this.setState({
            saveOption: true,
            slide: !this.state.slide
        })
    }

    goBackToModuleMenu(){
        this.setState({
            viewModuleMenu : true,
            activityEnCours: 0
        })
    }

    startSequence(event, indice){
        this.setState({
            slide: false,
        });
        setTimeout(function(){
            this.setState({
                viewModuleMenu : false,
                sequenceEnCours: indice
            });
        }.bind(this), 150);

        console.log("new seq started", indice);
        console.log("activité en cours", this.state.activityEnCours);
    }

    handle_Back(){
        this.props.backHandler();
    }

    goToSavedActivity(item){
        console.log(item);
        this.setState({
            viewModuleMenu : false,
            saveOption: false,
            activityEnCours: item.activity,
            sequenceEnCours: item.sequence,
            moduleEnCours: item.module,
        });
    }

    saveActivity(seq, activity){
        const { game, moduleEnCours } = this.state;
        let copyArray = game.saved;
        let r = this.insideSaved(seq, activity);
        if(r === false){
            copyArray.push({
                module: moduleEnCours,
                sequence: seq,
                activity: activity
            });
        }
        else{
            copyArray.splice(r, 1);
        }
        UserActions.changeUser({
            saved: copyArray
        });
        this.setState({
            saved: !this.state.saved,
            game: UserStore.getUser()
        });
    }

    insideSaved(seq, activity){
        const { game, moduleEnCours } = this.state;
        let copyArray = game.saved;
        let inside;
        if(copyArray.length === 0){
            inside = false;
        }
        else{
            inside = false;            
            for (var i = 0; i < copyArray.length; i++){
                if(copyArray[i].module === moduleEnCours){
                    if(copyArray[i].sequence === seq){
                        if(copyArray[i].activity === activity){
                            inside = i;
                        }
                    }
                }
            }   
        }
        return inside;
    }
    updateSeqComplete(){
        this.setState({displayNotif:false});
        UserActions.changeUser(
            {
                gamePath:this.state.game.gamePath
            }
        )
    }
    displayPageActivity(){
        const { sequenceEnCours, activityEnCours, game, moduleEnCours } = this.state;
        //let currentSequenceActivities = game.gamePath[moduleEnCours].module.sequences[sequenceEnCours];
        let currentSequenceActivities = window.APP_DATA.chapters[moduleEnCours].sequences[sequenceEnCours];
        let currentActiviteType = currentSequenceActivities.activities[activityEnCours].type;
        return (
            <Grid xs={12} sd={10} md={9} spacing={0} key={activityEnCours} className="top-page-activity">
                 {this.state.infoBulle&&<div className="info-bulle-2">{this.insideSaved(sequenceEnCours, activityEnCours) === false ?"Sauvegarder ce contenu":"Retirer ce contenu de la sauvegarde"}</div>}
                <Grid item xs={12} className="">
                        <div className={`steps-bloc-titre titre-bg-${this.state.moduleEnCours}`}>
                            <div className="steps-titre">
                                <h1 dangerouslySetInnerHTML={helpers.cleanHTML(window.APP_DATA.chapters[moduleEnCours].titre)}/>
                            </div>
                            <div className={`steps-sous-titre s-titre-bg-${this.state.moduleEnCours}`}>
                                 <p >{currentSequenceActivities.titre}</p>
                                 <CancelIcon 
                                    onClick={(e)=>this.goBackToModuleMenu()}
                                    className="close-btn"
                                    style={{color:'#3f3f3e'}}
                                    fontSize={"large"}
                                 />
                            </div>        
                        </div>
                    </Grid>
                    {(currentActiviteType === "INTRODUCTION") ? null :
                        <div className="save-btn">
                            <IconButton 
                                aria-label="Menu"
                                aria-owns={this.state.infoBulle?"mouse-over-popover":null}
                                aria-haspopup={true}
                                style={{ backgroundColor: 'transparent' }} 
                                onClick={() => this.saveActivity(sequenceEnCours, activityEnCours)}
                                onMouseEnter={(e)=>this.setState({infoBulle:Boolean(e.currentTarget),anchorE1:e.currentTarget})}
                                onMouseLeave={(e)=>this.setState({infoBulle:false,anchorE1:null})}
                                className="save-icon"
                                >
                                {this.insideSaved(sequenceEnCours, activityEnCours) === false ? 
                                    <BookmarkBorderIcon style={{fontSize : "1em"}}/>
                                    :
                                    <BookmarkIcon style={{fontSize : "1em"}}/>
                                }
                                {/* {<p>{helpers.findLabelInData("SAVE")}</p>} */}
                            </IconButton>
                       
                        </div>
                     }
                     <Swipe
                            onSwipeLeft={this.handleNextActivity}
                            onSwipeRight={this.handlePrevactivity}
                            onSwipeUp={()=>false}
                            onSwipeDown={()=>false}
                            onSwipeMove={()=>false}
                            onSwipeEnd={()=>true}
                            tolerance={50}
                            >
                          {this.displayActivity(currentSequenceActivities, currentActiviteType)}          
                    </Swipe>
                    
            </Grid>
        )
    }
    
    displayActivity(arrayActivities, ActivityType){
        let currentActiviteContent = arrayActivities.activities[this.state.activityEnCours];
        const imgIntro=`${window.PUBLIC_URL}${arrayActivities.thumbnail}`;
        const{moduleEnCours,sequenceEnCours,activityEnCours}=this.state
        const activities = window.APP_DATA.chapters[moduleEnCours].sequences[sequenceEnCours].activities;
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 300);
        }
        else {
            switch(ActivityType){
                case "INTRODUCTION" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div>
                                 <Introduction image={imgIntro} content={currentActiviteContent} couleur={moduleEnCours} handleNext={this.handleNextActivity}/>  
                            </div>
                            
                        </Slide>
                    )
                case "CONCLUSION" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                             <div className="arrow-container">
                                {activityEnCours!==0&&<div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>}
                                <Conclusion image={imgIntro} couleur={moduleEnCours} content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                            </div>
                            
                        </Slide>
                    )
                case "REVEAL" :
                        return (
                            <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                                <div className="arrow-container">
                                    <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                    <Reveal content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                    {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                                </div>          
                            </Slide>
                            )
                case "IMAGES" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                <Images content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                <div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>
                            </div>
                        </Slide>
                    )
                case "INFO":
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                 <Infographie couleur={moduleEnCours} image={imgIntro} content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                 <div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>
                            </div>
                        </Slide>
                    )
                case "QUIZZ" :
                   
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                <Quizz image={imgIntro} couleur={moduleEnCours} content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                            </div>
                        </Slide>
                    )
                case "DIAG" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                <Diag image={currentActiviteContent.thumbnail} couleur={moduleEnCours} content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                            </div>
                        </Slide>
                    )
                case "AUTODIAG" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                <Autodiag image={imgIntro} couleur={moduleEnCours} content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                            </div>
                        </Slide>
                    )
                case "VIDEO" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                    <Video content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                 <div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>
                            </div>
                            
                        </Slide>
                    )
                case "SIM" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <Sim content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                        </Slide>
                    )
                case "WHEEL" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <div className="arrow-container">
                                <div onClick={this.handlePrevactivity} className="arrow-back"><ArrowBackIosOutlinedIcon fontSize="large"/></div>
                                    <WheelRPS content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                                {activities.length!==activityEnCours+1&&<div onClick={this.handleNextActivity}className="arrow-fwd"><ArrowForwardIosOutlinedIcon fontSize="large"/></div>}
                            </div>
                        </Slide>
                    )
                case "CASES" :
                    return (
                        <Slide
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <Cases content={currentActiviteContent} handleNext={this.handleNextActivity}/>
                        </Slide>
                    )
                default :
                    break;
            }
        }
    }

    displaySequenceMenu(){
        const { game, moduleEnCours } = this.state;
        //const module=game.gamePath[moduleEnCours].module
        const module=window.APP_DATA.chapters[moduleEnCours]
        let moduleActivities = module.sequences;
        let moduleTitre =module.titre;
        let moduleResume = module.intro;
        let modulePres=module.presentation;
        this.checkProgressComplete()
        return (
            <Slide key={moduleEnCours} direction={'right'}
                in={this.state.slide}
                mountOnEnter unmountOnExit>
                <Grid item xs={12} sd={10} md={9} className="activity-viewer"> 
                    <Grid item xs={12}>
                        <div className="steps-bloc-titre">
                            <div className={`steps-titre titre-bg-${this.state.moduleEnCours}`}>
                                <h1 dangerouslySetInnerHTML={helpers.cleanHTML(moduleTitre)}/>
                            </div>
                            <div className={`steps-sous-titre s-titre-bg-${this.state.moduleEnCours}`}>
                                 <p >{moduleResume}</p>
                            </div>        
                        </div>
                    </Grid>
                 
                    <Grid item xs={12} className="activity-container">
                        {moduleActivities.map((item, key)=> {
                             console.log(item.thumbnail);
                            return(
                                    <Card key={key} className="activité-bloc-card" variant="outlined">
                                         {this.isCompleted(key)&&<Checked className="checked-icon"/>}
                                        <CardMedia
                                            className="card-thumbnail"
                                            image={`${window.PUBLIC_URL}${item.thumbnail}`}
                                        />
                                        <CardContent className="activité-bloc-text">
                                            <h4>{item.titre}</h4>
                                            <div dangerouslySetInnerHTML={{__html:item.abstract}}/>
                                            <Button 
                                                onClick={(event)=>this.startSequence(event, key)}
                                                className={`button-activity btn-bg-${this.state.moduleEnCours}`}>
                                                    {helpers.findLabelInData("BTN-ACTIVITY")}
                                            </Button>                                        
                                        </CardContent>
                                    </Card>
                            )
                        })}
                    </Grid>
                </Grid>
            </Slide>
        )
    }

    displaySauvegardes(){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 300);
        }
        else{
           return(
                <Slide
                    direction={'left'}
                    in={this.state.slide}
                    mountOnEnter unmountOnExit>
                    <Sauvegarde goToActivity = {this.goToSavedActivity}/>
                </Slide>
            ) 
        }
    }

    render(){
        const {viewModuleMenu, saveOption,moduleEnCours,game} = this.state;
        const text=`<p>Vous avez complété la section <b>${window.APP_DATA.chapters[moduleEnCours].titre}</b></p><p>Vous pouvez maintenant revenir à l'accueil pour découvrir une nouvelle section.</p>`
        console.log("module en cours : ", moduleEnCours)
        return(
            <Grid container spacing={0} className={`s-viewer m-background-${moduleEnCours}`}>
                {(game.gamePath[moduleEnCours].progress===100&&this.state.displayNotif)&&
                <Notification text={text} fired={this.state.displayNotif} callBack={()=>this.updateSeqComplete()}/>
                }
                <NavBar             
                    goHome = {this.handle_Back}
                    goSave = {this.goToSauvegarde}
                />
                {saveOption ? this.displaySauvegardes() : [
                    viewModuleMenu ? this.displaySequenceMenu() : this.displayPageActivity()
                ]}
            </Grid>
        ) 
        
    }
}
export default Steps;